<template>
  <v-card>
    <v-card-subtitle>
      <span class="lstick"></span>
      <h3 class="title font-weight-regular">Estad&iacute;sticas referentes a Categor&iacute;as</h3>
    </v-card-subtitle>
    <v-card-text>
      <v-data-table :headers="headers" :items="items" :loading="loading" :items-per-page="12" item-key="id"
                    no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
                    class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import dashBoardService from '@/providers/DashboardService';

export default {
  name: "StadisticEntities",
  data(){
    return {
      items: [],
      headers: [
        {
          text: "Entidad",
          align: "start",
          value: "name",
        },
        {
          text: "Básico",
          align: "start",
          value: "basic",
        },
        {
          text: "Member",
          align: "start",
          value: "member",
        },
        {
          text: "Premium",
          align: "start",
          value: "premium",
        },
      ],
      loading: true,
    }
  },
  methods: {
    getItems(){
      this.loading = true;
      dashBoardService.getStadisticEntities().then(resp=>{
        this.items = resp.data.value;
      }).catch(err=>console.log(err)).finally(()=>{
        this.loading = false;
      })
    }
  },
  mounted() {
    this.getItems();
  }
}
</script>

<style scoped>

</style>